import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
// import Moveable from 'react-moveable';
// import Selecto from 'react-selecto';
import "./index.css";
// import { Line, Doughnut, Bar } from 'react-chartjs-2';
// import { Bar } from 'react-chartjs-2';
// import { Sparklines, SparklinesBars } from 'react-sparklines';
// import { ProgressBar, Dropdown } from 'react-bootstrap';
// import { Dropdown } from 'react-bootstrap';
import { firebase } from "../../firebase";
import Spinner from "../../shared/Spinner";
// import { confirmAlert } from 'react-confirm-alert'; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// import { connect } from 'react-redux'
// import { loadStory, savePage, setPage, setDirtyStory } from '../../store/actions'
// import { getPage } from '../../store/selectors';
// import Gallery from '../../components/Gallery';

// import DatePicker from 'react-datepicker';
// import { Dropdown } from 'react-bootstrap';

export class BookCatalog extends Component {
  constructor() {
    super();

    this.container = React.createRef();

    this.state = {
      schoolId: null,
      teacherId: null,
      studentClass: null,
      teacherClass: null,
      studentList: null,
      storyData: {},
    };

    this.inputMaxLength = this.inputMaxLength.bind(this);
  }

  componentWillUnmount() {
    firebase.db.ref("escolas").child(this.state.schoolId).child("alunos").off();
  }

  componentDidMount() {
    this.loadBookFromClass();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.loadBookFromClass();
    }
  }

  loadBookFromClass = () => {
    const { authData } = this.props;

    const userData = authData != null ? authData.userData : null;

    if (userData != null) {
      const schoolId = userData["codEscola"];
      const studentClass = userData["turma"];
      const teacherClass = userData["turmaProfessor"];
      const teacherId = userData.key;

      // this.props.setPage(pageNum);

      // let msgPlatform = this.state.msgPlatform;

      // if (userData['msg_plataforma'] != null) {
      //   msgPlatform = userData['msg_plataforma'];
      // }

      // LER O FIREBASE AQUI E CARREGAR NO STATE

      this.setState({
        loading: true,
        schoolId,
        teacherId,
        studentClass,
        teacherClass,
        studentList: null,
        storyData: {},
      });

      //const snapshot = await
      firebase.db
        .ref("cadastroWeb")
        // .child(schoolId)
        // .child('alunos')
        .orderByChild("turma")
        .equalTo(teacherClass)
        .on("value", (snapshot) => {
          const data = snapshot.val();

          if (data) {
            const studentList = Object
              // .entries(data)
              .values(data)
              // .map(s => ({
              //     "codigo_aluno": s[0],
              //     "codEscola": s[1]["codEscola"],
              //     "nome": s[1]["nome"],
              //   })
              // )
              .filter((s) => s["codEscola"] === schoolId)
              .sort((a, b) => a["nome"].localeCompare(b["nome"]));

            // console.log(studentList);

            this.setState({ studentList });
          }
        });

      //

      // Object.keys(studentList).forEach(k => {
      //   firebase.db
      //     .ref('minhasHistoriasWeb')
      //     .child(k)
      //     .child('slot0/paginas/0')
      //     .once('value', snapStudent => {
      //       const coverData = snapStudent.val;

      //       if (coverData) {
      //         const s = {

      //         };

      //       }
      //     });
      // });

      // const defaultStory = {
      //   slot0: {
      //     modoHistoria: "story",
      //     paginas: Array(11),
      //     slot: 0,
      //     totalDePaginas: 10,
      //   }
      // };

      // const story = snapshot.val() || defaultStory;

      // const page = story.slot0.paginas && story.slot0.paginas[pageNum]

      // this.listBox = (page && page.sprites) || [];

      // const selectedScenario = page && page.scenario;

      // const isDirty = this.state.isDirty;

      // this.setState({ story, isDirty, selectedScenario, loading: false }, () => {
      //   if (parseInt(pageNum) === 0) {
      //     const titleElement = this.listBox.filter((l) => l.isTitle);

      //     if (titleElement.length === 0) {
      //       this.createElement({ }, 7, "Título", true);
      //     }
      //   }

      //   this.setState({});
      // });

      this.setState({ loading: false });
    }
  };

  // loadPageData = async () => {
  //   const { authData } = this.props;

  //   this.listBox = [];

  //   if (this.state.selectedTarget && (this.state.selectedTarget.nodeName === "INPUT" || this.state.selectedTarget.isContentEditable) ) {
  //     this.state.selectedTarget.blur();
  //   }

  //   this.setState({
  //     selectedTarget: null,
  //     canScaleAndRotate: true,
  //     // containerWidth: this.getWidth(),
  //     // containerHeight: this.getHeight()
  //   });

  //   const userData = authData != null ? authData.userData : null;

  //   }
  // };

  isPathActive(path) {
    return this.props.location.pathname === path;
  }

  inputMaxLength = (event) => {
    const text = event.target.innerText || event.target.textContent;
    let maxlength = parseInt(event.target.getAttribute("maxLength"));

    if (isNaN(maxlength)) {
      maxlength = 80;
    }

    // console.log("   >>>>>> : " + text + " /// maxlength: " + maxlength + " /// length: " + text.length);

    if (text.length > maxlength) {
      event.preventDefault();
    }
  };

  render() {
    if (this.state.loading || this.state.studentClass == null) {
      return <Spinner />;
    }

    // if (isNaN(this.state.pageNum)) {
    //   return <Redirect to="/book/0" />;
    // }

    // const { authData } = this.props;
    // const userData = authData != null ? authData.userData : null;

    if (this.state.studentClass !== "Professor") {
      return <Redirect to={`/book/${this.state.teacherId}/0`} />;
    }

    return (
      <div className="bookCatalogContainer">
        <div className="my-4">
          <div
            style={{
              display: "flex",
              flex: 1,
              // width: "100%",
              // height: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h4>{`Revisão de Histórias - Turma: ${this.state.teacherClass}`}</h4>
            <Link className="btn btn-outline-primary mx-1 active" to={`/book/${this.state.teacherId}/0`}>
              Crie sua história
            </Link>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th> Código </th>
                    <th> Nome </th>
                    <th> Ebook </th>
                    {/* <th> Progresso </th>
                        <th> Total de Páginas </th>
                        <th> Editar páginas </th>
                        <th> Baixar gabarito </th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.studentList &&
                    this.state.studentList.map((s) => (
                      <tr key={s["codigo_aluno"]}>
                        <td className="font-weight-medium" data-href={`/book/${s["codigo_aluno"]}/0`} onClick={() => this.props.history.push(`/book/${s["codigo_aluno"]}/0`)}>
                          {s["codigo_aluno"]}
                        </td>
                        <td data-href={`/book/${s["codigo_aluno"]}/0`} onClick={() => this.props.history.push(`/book/${s["codigo_aluno"]}/0`)}>
                          {s["nome"]}
                        </td>
                        <td>
                          <a href={`https://www.imaginakids.com.br/ebook/?codAluno=${s["codigo_aluno"]}`} rel="noopener noreferrer" target="_blank">
                            <i className="fa fa-book fa-lg"></i>
                          </a>
                        </td>
                        <td>{/* <ProgressBar variant={s['num_paginas']/this.state.totalPages * 100 > 80 ? 'info' : 'danger'} striped now={s['num_paginas']/this.state.totalPages * 100}/> */}</td>
                        {/* {
                              s['num_paginas']/this.state.totalPages * 100 > 80 ?
                              <td className="text-center"> {s['num_paginas']} </td> :
                              <td className="text-center text-danger"> {s['num_paginas']} </td>
                            } */}

                        {/* <td className="text-danger"> 53.64% <i className="mdi mdi-arrow-down"></i>
                            </td> */}
                        {/* <td className="text-center">
                              <a href="!#" onClick={event => {
                                event.preventDefault(); this.props.history.push(`/class/${classId}/${s['codigo_aluno']}`);
                              }}>
                                <i className="fa fa-edit fa-lg"></i>
                              </a>
                            </td>
                            <td className="text-center"><a href="!#" onClick={event => {event.preventDefault(); genStudent(this.props.authData, s) }}><i className="fa fa-file-pdf-o fa-lg"></i></a></td> */}
                        {/* <td> May 15, 2015 </td> */}
                        {/* </Link> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default connect(
//   state => ({ page: getPage(state.story), isDirty: state.isDirty }),
//   { loadStory, savePage, setPage, setDirtyStory }
// )(PageEditor)

export default BookCatalog;
